<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'

import { Popover, PopoverPanel } from '@headlessui/vue'
import { useScroll } from '@vueuse/core'

import * as yup from 'yup'
import { useForm } from 'vee-validate'

import { format, isSameDay, isThisYear, isToday } from 'date-fns'

import { useAccountStore } from '@/stores/account'

import type { SectionMessageBaseModel } from '@/types/api/models/section-message.base'
import type { SectionMessageFullModel } from '@/types/api/models/section-message.full'
import type { UserCommonModel } from '@/types/api/models/user.common'

import { toTypedSchema } from '@vee-validate/yup'


type MessageGroup = {
  date: Date
  dateStr: string
  messages: SectionMessageFullModel[]
}


const props = defineProps<{
  /**
  * Array of messages as returned by backend (i.e. ordered by createdAt, DESC)
  */
  messages: SectionMessageFullModel[]
  sectionStaff: UserCommonModel[]
  /**
  * Only provided if using small screen
  */
  sectionName?: string
}>()

const emit = defineEmits<{
  (e: 'load-old-messages', before: Date): void
  (e: 'post-message', message: SectionMessageBaseModel): void
  (e: 'viewed-messages', ids: Set<string>): void
  (e: 'go-back'): void
}>()


const messagespane = ref<HTMLElement | null>(null)

const accountStore = useAccountStore()
const scrollUtils = useScroll(messagespane, { behavior: 'smooth' })

const messageSchema = yup.object({
  text: yup.string().trim().required().min(1),
})

const { handleSubmit, meta } = useForm({
  validationSchema: toTypedSchema(messageSchema),
})


const getMaxY = () => {
  const paneEl = messagespane.value
  if (!paneEl) {
    return 0
  }
  const maxY = paneEl.scrollHeight - paneEl.offsetHeight
  return maxY
}

/**
* Returns current "scroll position", defined as distance from bottom of
* scrollable space.*/
const getScrollPosition = () => {
  return getMaxY() - scrollUtils.y.value
}

const canScroll = () => {
  const paneEl = messagespane.value
  if (!paneEl) {
    return false
  }
  return paneEl.scrollHeight > paneEl.offsetHeight
}

/**
* Sets "scroll position", defined as distance from bottom of scrollable space.
* */
const setScrollPosition = (pos: number) => {
  scrollUtils.y.value = getMaxY() - pos
}

const updateViewedMessages = () => {
  if (!messagespane.value) {
    return;
  }
  const paneEl = messagespane.value
  const { top: paneTop, bottom: paneBottom } = paneEl.getBoundingClientRect()
  const messageEls = paneEl.querySelectorAll('.message') as NodeListOf<HTMLElement>
  messageEls.forEach(el => {
    const { top: elTop, bottom: elBottom } = el.getBoundingClientRect()
    if (elTop >= paneTop && elBottom <= paneBottom) {
      if (!el.dataset.messageId) {
        console.error('missing message ID')
        return;
      }
      if (el.dataset.isRead === 'true') {
        console.log('message already viewed')
        return;
      }
      viewedMessageIds.add(el.dataset.messageId)
    }
  })
}

const viewedMessageIds = new Set<string>()

/**
* TODO
* 1.  In case where oldMs empty (change section), always scroll to bottom.
* 2.  **Only** in case where new messages insufficient to give any scroll space,
*     update viewed messages.
*/
watch(() => props.messages, async (ms, oldMs) => {
  const idSet = new Set(ms.map(m => m.id))
  const oldIds = oldMs.map(m => m.id)
  if (idSet.size === oldIds.length && oldIds.every(id => idSet.has(id))) {
    return;
  }
  const distanceFromBottom = getScrollPosition()
  await nextTick()
  if (canScroll()) {
    setScrollPosition(oldMs.length ? distanceFromBottom : 0)
    return;
  }
  updateViewedMessages()
  emit('viewed-messages', viewedMessageIds)
  viewedMessageIds.clear()
})

watch(scrollUtils.isScrolling, isScrolling => {
  if (!isScrolling) {
    updateViewedMessages()
    emit('viewed-messages', viewedMessageIds)
    viewedMessageIds.clear()
    return;
  }
})

const createGroup = (m: SectionMessageFullModel) => {
  return {
    date: m.createdAt,
    dateStr: isToday(m.createdAt)
      ? 'Today'
      : isThisYear(m.createdAt)
        ? Intl.DateTimeFormat(undefined, { weekday: 'short', month: 'short', day: 'numeric' }).format(m.createdAt)
        : Intl.DateTimeFormat(undefined, { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' }).format(m.createdAt),
    messages: [m],
  }
}

const messageGroups = computed(() => {
  return props.messages
    .slice()
    .reverse()
    .reduce((acc, m) => {
      if (!acc.length) {
        acc.push(createGroup(m))
        return acc
      }

      const currentGroup = acc[acc.length - 1]
      if (isSameDay(currentGroup.date, m.createdAt)) {
        currentGroup.messages.push(m)
      } else {
        acc.push(createGroup(m))
      }

      return acc
    }, [] as MessageGroup[])
})

const getBeforeDate = () => {
  if (!props.messages.length) {
    // defaults to 24 hours before current time
    return new Date(new Date().getTime() - 86400000)
  }
  return props.messages[props.messages.length - 1].createdAt
}

const imageHoverUserId = ref<string | undefined>()

const onUserImageEnter = (userId: string) => {
  imageHoverUserId.value = userId
}
const onUserImageLeave = () => {
  imageHoverUserId.value = undefined
}

const isRead = (message: SectionMessageFullModel) => {
  const userId = accountStore.user?.id
  if (!userId || !message.viewedBy?.length) {
    return false
  }
  return message.viewedBy.some(u => u.id === userId)
}

const onSubmit = handleSubmit((data, actions) => {
  emit('post-message', data)
  actions.resetForm()
})
</script>

<template>
  <div class="flex flex-col flex-1 rounded border border-zinc-500">
    <div class="flex flex-row border-b border-zinc-500 p-4 justify-between items-center">
      <button v-if="sectionName" @click="$emit('go-back')">
        Back
      </button>

      <span v-if="sectionName" class="inline text-2xl font-extrabold truncate">
        {{ sectionName }}
      </span>

      <div class="flex flex-row justify-center max-w-[50%] overflow-hidden">
        <Popover>
          <PopoverPanel>
            <div class="flex flex-col">
              <div
                v-for="staff in sectionStaff"
                :key="staff.id"
                class="flex flex-row items-center"
              >              
                <img
                  v-if="staff.imageId"
                  :src="staff.imageId"
                  :alt="staff.name"
                  class="rounded-full w-8 h-8"
                  @mouseenter="onUserImageEnter(staff.id)"
                  @mouseleave="onUserImageLeave"
                />

                <span class="ml-2">{{ staff.name }}</span>
              </div>
            </div>
          </PopoverPanel>

          <button class="flex flex-row items-center">
            <font-awesome-icon :icon="['fas', 'users']"></font-awesome-icon>
            <span class="ml-2">{{ sectionStaff.length }}</span>
          </button>
        </Popover>
      </div>
    </div>

    <div ref="messagespane" class="flex-1 flex flex-col scrollbar">
      <div class="flex flex-1 justify-center my-4">
        <div class="flex flex-col justify-end">
          <button
            class="btn-primary"
            @click="$emit('load-old-messages', getBeforeDate())"
          >
            Load more messages
          </button>
        </div>
      </div>

      <div
        v-for="group in messageGroups"
        :key="group.dateStr"
        class="flex flex-col"
      >
        <div class="flex justify-center">
          <div class="border-2 rounded-lg border-zinc-400 py-2 px-8">
            {{ group.dateStr }}
          </div>
        </div>

        <div
          v-for="message in group.messages"
          :key="message.id"
          :data-message-id="message.id"
          :data-is-read="isRead(message)"
          :class="!isRead(message) ? 'bg-light' : ''"
          class="message flex flex-row my-2 rounded-lg"
        >
          <font-awesome-icon
            :icon="['fas', 'user']"
            class="text-5xl m-2"
            :class="message.authorId === accountStore?.user?.id ? 'order-2' : 'order-1'"
          ></font-awesome-icon>

          <div
            class="flex-1"
            :class="message.authorId === accountStore?.user?.id ? 'order-1 text-right' : 'order-2'"
          >
            <p>
              <span>{{ message.author?.name || 'Annonymous user' }}</span>
              <span class="ml-8">
                {{ format(message.createdAt, 'h:mm aaa') }}
              </span>
            </p>

            <p>{{ message.text }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="border-2 rounded border-zinc-200 p-2">
      <form
        :validation-schema="messageSchema"
        @submit="onSubmit"
        class="flex flex-row"
      >
        <button type="button" class="my-2 mx-3">
          <font-awesome-icon :icon="['fas', 'paperclip']"></font-awesome-icon>
        </button>

        <Field name="text" type="text" class="flex-1 bg-transparent" />

        <button
          :disabled="!meta.valid"
          type="submit"
          class="my-2 mx-3"
        >
          <font-awesome-icon :icon="['fas', 'paper-plane']"></font-awesome-icon>
        </button>
      </form>
    </div>
  </div>
</template>
