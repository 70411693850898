import { createRouter, createWebHistory } from 'vue-router'

import { useAccountStore } from '@/stores/account'
import { useAuthStore } from '@/stores/auth'
import { useOrganizationStore } from '@/stores/organization'

import AccountLogin from '@/views//account/AccountLogin.vue'
import AccountSignup from '@/views/account/AccountSignup.vue'

import PrepListHome from '@/views/prep-list/prep-list-home/PrepListHome.vue'
import ConnectionHome from '@/views/connection/connection-home/ConnectionHome.vue'
import MessageHome from '@/views/message/message-home/MessageHome.vue'

import {registerPushNotifications} from '@/utils/firebase'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: AccountLogin,
    },
    {
      path: '/signup',
      name: 'signup',
      component: AccountSignup,
    },
    {
      path: '/prep-lists',
      name: 'prep-list-home',
      component: PrepListHome,
    },
    {
      path: '/connections',
      name: 'connection-home',
      component: ConnectionHome,
    },
    {
      path: '/messages',
      name: 'message-home',
      component: MessageHome,
    },
    { 
      path: '/',
      name: 'home',
      component: null,
      children: [],
    },
  ],
})

router.beforeEach(async (to, from) => {
  const { isAuthenticated } = useAuthStore()

  if (to.name === 'login' || to.name === 'signup') {
    return true
  }

  if (!isAuthenticated()) {
    if (from.name === 'signup') {
      return false
    }
    return { name: 'login' }
  }

  if (to.name === 'home') {
    const accountStore = useAccountStore()
    const orgStore = useOrganizationStore()
    if (orgStore.organizations.length === 0) {
      await orgStore.fetchOrganizations()
    }
    const organizations = orgStore.organizations;
    if (organizations.some(org => org.sections.length ||
      org.team.find(user => user.id === accountStore.user?.id)?.role === 'team_member')) {
      return { name: 'prep-list-home' }
    }
    return { name: 'connection-home' }
  }

  return true
})

router.afterEach(() => {
  const { isAuthenticated } = useAuthStore()
  if (isAuthenticated())
    registerPushNotifications()
})

export default router
